// Use This URL for testing
const URL = "calibration.360techfusion.com";

export const BASE_URL = `https://${URL}/api/`;
export const DOMAIN = `https://${URL}`;
export const MAX_COLS = 30;
export const ID_BASE = 1;
export const SRF_ID_BASE = 1;

// TODO: need to move to DB
export const BANK_DETAILS = {
  "Bank Name": "",
  "Bank A/c. No.": "",
  "IFSC Code": "",
};
export const GST_NUMBER = "";

export const DISABLED_MODULE =[]; 

export const DEVICE_TYPE = ["desktop", "tablet", "mobile"];
